import React from 'react';
import Seo from '../../components/seo/SeoTags';
import * as styles from './Master.module.scss';

export default class Master extends React.Component {
    render () {
        return (
            <div className={styles.appMaster} id='appMaster'>
                <Seo />
                {this.props.children}
            </div>
        );
    }
}