import React from 'react';
import Content from '../../components/content/Content';
import * as styles from './Sustainability.module.scss';
import handsbwImage from '../../assets/images/content/handsbw.jpg';

export default function Sustainability () {
    return (
        <Content>
            <h1 className={styles.header}>
                <span className={styles.headerLine}><strong>Celebrating Nature</strong></span>
                <span className={styles.headerLine}><strong>in our homes</strong></span>
            </h1>
            <p className={styles.sustainabilityText}>
                With this ever so fast evolving world we believe it is important to not
                forget about our roots in Nature. For years humans have taken more than we have given back
                and now we find ourselves in a position close to no return. Today the sustainability of products
                is more important than ever. That is why we at <span className={styles.brandHighlight}>Francisca Bullinger</span> are
                doing our part to help save the planet.
            </p>
            <p className={styles.sustainabilityText}>
                Therefore, every piece of furniture we make has to meet at least one of the following criteria:
            </p>
            <div className={styles.emphasisContainer}>
                <ul className={styles.emphasisList}>
                    <li>
                        Use <strong className={styles.emphasisHighlight}>sustainable / eco-friendly</strong> materials
                    </li>
                    <li>
                        Use <strong className={styles.emphasisHighlight}>recycled or reclaimed</strong> materials
                    </li>
                    <li>
                        Use products from <strong className={styles.emphasisHighlight}>national or local</strong> suppliers with
                        a preference for small or independent businesses
                    </li>
                </ul>
            </div>
            <div className={styles.imageWrap}>
                <img className={styles.sustainabilityImage} src={handsbwImage} alt='Chair building in the workshop' />
            </div>
        </Content>
    );
}