import React from 'react';
import NavBar from '../../components/navigation/NavBar';
import NavItem from '../../components/navigation/NavItem';
import NavLogo from '../../components/navigation/NavLogo';
import * as styles from './Nav.module.scss';

class Nav extends React.Component {
    constructor (props) {
        super(props);

        this.menuRef = React.createRef();
    }

    clickItem = (ref) => (e) => {
        e.preventDefault();

        const menuHeight = this.menuRef.current.getBoundingClientRect().height;
        const targetElementTop = ref.current.offsetTop;
        const scrollPos = Math.max(0, targetElementTop - menuHeight);

        window.scrollTo({
            top: scrollPos,
            behavior: 'smooth'
        });
    }

    render () {
        return (
            <NavBar menuRef={this.menuRef}>
                <NavLogo />
                <ul className={styles.navList}>
                    <NavItem onClick={this.clickItem(this.props.furnitureRef)}>Furniture</NavItem>
                    <NavItem onClick={this.clickItem(this.props.aboutRef)}>About</NavItem>
                    <NavItem onClick={this.clickItem(this.props.contactRef)}>Contact</NavItem>
                </ul>
            </NavBar>
        );
    }
}

export default Nav;