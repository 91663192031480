import React from 'react';
import classnames from 'classnames/bind';
import * as styles from './NavItem.module.scss';

const cx = classnames.bind(styles);
const NavItem = (props) => {
    return (
        <li className={cx('navigationItem', { active: props.isActive })}>
            <a href='#' onClick={props.onClick}>{props.children}</a>
        </li>
    );
};

export default NavItem;