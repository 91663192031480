import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Nav from '../views/nav/Nav';
import Master from '../views/master/Master';
import Furniture from '../views/furniture/Furniture';
import Splash from '../components/splash/Splash';
import Sustainability from '../views/sustainability/Sustainability';
import Contact from '../views/contact/Contact';
import Footer from '../views/footer/Footer';
import * as styles from './index.module.scss';

export default function Index () {
    const furnitureRef = React.createRef();
    const aboutRef = React.createRef();
    const contactRef = React.createRef();
    const data = useStaticQuery(graphql`
        query MyQuery {
            indexImage: file(relativePath: { eq: "splash/indexSplash.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            sustainabilityImage: file(relativePath: { eq: "splash/sustainSplash.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            contactImage: file(relativePath: { eq: "splash/contactSplash.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    console.log(data);

    return (
        <Master>
            <Nav
                furnitureRef={furnitureRef}
                aboutRef={aboutRef}
                contactRef={contactRef}
            />
            <Splash
                heading='Handmade Furniture'
                subheading='Francisca Bullinger'
                splashImage='indexImage'
                sectionRef={furnitureRef}
                image={data.indexImage}
            >
                Fine furnishing design and production based in Edinburgh using sustainably sourced materials
            </Splash>
            <Furniture />
            <Splash
                heading='Sustainability'
                subheading='We Value'
                splashImage='sustainImage'
                extraClasses={styles.sustainHeader}
                sectionRef={aboutRef}
                image={data.sustainabilityImage}
            >
                Contributing to protecting our environment by sticking to eco-conscious sources
            </Splash>
            <Sustainability />
            <Splash
                heading='Get in touch'
                splashImage='contactImage'
                sectionRef={contactRef}
                image={data.contactImage}
            >
                Whether you are looking for a bespoke furniture solution for your home
                or are interested in one of our designs - we are here to help.
            </Splash>
            <Contact />
            <Footer />
        </Master>
    );
}
