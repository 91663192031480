import React from 'react';
import AriaModal from 'react-aria-modal';
import Img from 'gatsby-image';
import * as styles from './GalleryOverlay.module.scss';

export default function GalleryOverlay (props) {
    return (
        <AriaModal
            onExit={props.onExit}
            titleText={props.title}
            focusDialog={true}
            getApplicationNode={props.getApplicationNode}
            underlayStyle={{
                cursor: 'default'
            }}
        >
            <div>
                {props.data.edges.map((image, index) => (
                    <div className={styles.imageWrap} key={index}>
                        <Img
                            fluid={{
                                ...image.node.childImageSharp.fluid
                            }}
                            alt={image.node.base.split('.')[0]}
                            className={styles.imageItem}
                        />
                    </div>
                ))}
            </div>
            <button onClick={props.onClose} title='Close' className={styles.closeButton}>&times;</button>
        </AriaModal>
    );
}