import React from 'react';
import Obfuscate from 'react-obfuscate';
import Content from '../../components/content/Content';
import * as styles from './Contact.module.scss';

export default function Contact () {
    return (
        <Content>
            <div className={styles.contactContainer}>
                <div className={styles.contactColumn}>
                    <h4>Note: we are currently not taking any more commissions. Please contact me however for any other enquiries or opportunities.</h4>
                    <h4 className={styles.contactHeader}>Email</h4>
                    <p>
                        <Obfuscate
                            email='contact@franbdesign.com'
                            headers={{
                                subject: 'Furniture enquiry'
                            }}
                        />
                    </p>
                    {/* <h4 className={styles.contactHeader}>Call us</h4>
                    <p>
                        <Obfuscate tel='+447306398557' />
                    </p> */}
                    <h4 className={styles.contactHeader}>Social Media</h4>
                    <p>
                        <a href='https://www.instagram.com/craftsfran/'>@craftsfran</a>
                    </p>
                </div>
                {/*
                <div className={styles.contactColumn}>
                    <iframe 
                        className={styles.contactMap}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.2479517128077!2d-2.742657598524876!3d55.91718316289744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887a7384e5f1927%3A0x2d6740fd93a24ecd!2sChippendale%20International%20School%20of%20Furniture!5e0!3m2!1sen!2suk!4v1601765452442!5m2!1sen!2suk" 
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0">
                    </iframe>
                </div>
                */}
            </div>
        </Content>
    );
}