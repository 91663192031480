import React from 'react';
import * as styles from './Content.module.scss';

const Content = (props) => {
    return (
        <div ref={props.ref} className={styles.containerWrapper}>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

export default Content;