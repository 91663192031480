import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import * as styles from './Splash.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);
const Splash = (props) => {
    return (
        <div ref={props.sectionRef}>
            <BackgroundImage className={cx('splash', props.extraClasses)} fluid={props.image.childImageSharp.fluid}>
                <div className={cx(styles.splashInternalWrapper, props.splashImage)}>
                    { (props.subheading || props.heading) &&
                        <div className={styles.splashHeadingContainer}>
                            { props.subheading &&
                                <div className={styles.splashSubheading}>
                                    <h2>{props.subheading}</h2>
                                </div>
                            }
                            { props.heading &&
                                <div className={styles.splashHeading}>
                                    <h1>{props.heading}</h1>
                                </div>
                            }
                        </div>
                    }
                    {props.children}
                </div>
            </BackgroundImage>
        </div>
    );
};

export default Splash;