import React from 'react';
import * as styles from './NavLogo.module.scss';
import LogoImage from '../../assets/images/logo.png';

class NavItem extends React.Component {
    render () {
        return (
            <span className={styles.headerLogo}>
                <img src={LogoImage} alt='FranB Design Logo' />
            </span>
        );
    }
}

export default NavItem;