import React from 'react';
import classnames from 'classnames/bind';
import Img from 'gatsby-image';
import * as styles from './ProjectItem.module.scss';

const cx = classnames.bind(styles);
const ProjectItem = (props) => {
    const headingColour = cx(styles[`${props.headingColour}Heading`]);
    return (
        <div className={styles.projectItemContainer}>
            <Img fluid={props.image.childImageSharp.fluid} alt={props.imageAlt} className={styles.itemImage} />
            <div className={styles.itemDescriptionWrap}>
                <div className={styles.itemDescriptionBox}>
                    <h3 className={headingColour}>{props.heading}</h3>
                    <p>{props.subheading}</p>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default ProjectItem;