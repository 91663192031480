import React from 'react';
import GalleryOverlay from './GalleryOverlay';
import * as styles from './GalleryActivate.module.scss';

export default class GalleryActivate extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isActive: false,
        };
    }

    activateModal = () => {
        this.setState({ isActive: true });
    };
    
    deactivateModal = () => {
        this.setState({ isActive: false });
    };
    
    getApplicationNode = () => {
        return document.getElementById('application');
    };

    render () {
        return (
            <React.Fragment>
                <button onClick={this.activateModal} className={styles.viewButton}>
                    {this.props.children}
                </button>
                { this.state.isActive &&
                    <GalleryOverlay
                        title={this.props.galleryTitle}
                        onClose={this.deactivateModal}
                        onExit={this.deactivateModal}
                        getApplicationNode={this.getApplicationNode}
                        data={this.props.data}
                    />
                }
            </React.Fragment>
        );
    }
}