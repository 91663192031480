import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Content from '../../components/content/Content';
import ProjectItem from '../../components/project-item/ProjectItem';
import * as styles from './Furniture.module.scss';
import GalleryActivate from '../../components/gallery/GalleryActivate';

export default function Furniture () {
    const galleryQuery = useStaticQuery(graphql`
        query {
            table: allFile (
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "gallery/table" }
                }
            ) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            bench: allFile (
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "gallery/bench" }
                }
            ) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            cabinet: allFile (
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "gallery/cabinet" }
                }
            ) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            coaster: allFile (
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "gallery/coaster" }
                }
            ) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            mirror: allFile (
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "gallery/mirror" }
                }
            ) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            sidetable: allFile (
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "gallery/sidetable" }
                }
            ) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            tablePromo: file(relativePath: { eq: "content/project/table.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            benchPromo: file(relativePath: { eq: "content/project/bench.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cabinetPromo: file(relativePath: { eq: "content/project/cabinet.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            coasterPromo: file(relativePath: { eq: "content/project/coaster.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mirrorPromo: file(relativePath: { eq: "content/project/mirror.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            sidetablePromo: file(relativePath: { eq: "content/project/sidetable.jpg" }) {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <React.Fragment>
            <Content>
                <h1 className={styles.header}>
                    We love
                    <span className={styles.headerTagline}><strong>Nature + Design + Craft</strong></span>
                </h1>
                <p className={styles.furnitureText}>
                    The colour of the stormy sea, the different textures of leaves, the shapes of stones and rocks.
                    There is so much beauty to be found in nature that it is without doubt our greatest source for inspiration.
                    To us nature is a place of calm and happiness and we want to welcome more of it into our homes. 
                </p>
                <p className={styles.furnitureText}>
                    All our furniture is handmade and unique, whether you are interested in one of our designs or are
                    looking for a piece that is personalised to your needs and desires. Our aim is to celebrate textures, shapes
                    and colours found in nature and unite them with modern furniture design. 
                </p>
                <p className={styles.furnitureText}>
                    Our standards of high quality furniture are met with every project no matter the size. We love working in
                    a range of different techniques. From initial manual and digital drawings during the concept development
                    to traditional joinery, wood bending techniques, marquetry, carving, and gilding in the production of the piece. 
                </p>
            </Content>
            <div className={styles.furnitureContentContainer}>
                <ProjectItem
                    image={galleryQuery.sidetablePromo}
                    alt='Thistle sidetable image'
                    heading='Thistle side table'
                    subheading='Scottish thistle veneer design on robust side table'
                    headingColour='green'
                >
                    <GalleryActivate
                        galleryTitle='Thistle Side Table Gallery'
                        data={galleryQuery.sidetable}
                    >
                        View
                    </GalleryActivate>
                </ProjectItem>
                <ProjectItem
                    image={galleryQuery.coasterPromo}
                    alt='Coasters stacked image'
                    heading='Flora Coasters'
                    subheading='Flowery sets of coasters made with veneer'
                    headingColour='yellow'
                >
                    <GalleryActivate
                        galleryTitle='Coasters Gallery'
                        data={galleryQuery.coaster}
                    >
                        View
                    </GalleryActivate>
                </ProjectItem>
                <ProjectItem
                    image={galleryQuery.cabinetPromo}
                    alt='Cabinet image'
                    heading='Forest cabinet'
                    subheading='Hand stained veneers and carved handles'
                    headingColour='orange'
                >
                    <GalleryActivate
                        galleryTitle='Cabinet Gallery'
                        data={galleryQuery.cabinet}
                    >
                        View
                    </GalleryActivate>
                </ProjectItem>
            </div>
            <div className={styles.furnitureContentContainer}>
                <ProjectItem
                    image={galleryQuery.tablePromo}
                    alt='Coffee table image'
                    heading='Raindrop coffee table'
                    subheading='Hard wearing oak with a liming wax finish'
                    headingColour='green'
                >
                    <GalleryActivate
                        galleryTitle='Coffee Table Gallery'
                        data={galleryQuery.table}
                    >
                        View
                    </GalleryActivate>
                </ProjectItem>
                <ProjectItem
                    image={galleryQuery.mirrorPromo}
                    alt='Mirror rendered image'
                    heading='Head in the Clouds'
                    subheading='Mirror with reflective gold leaf to create a nature scene effect'
                    headingColour='yellow'
                >
                    <GalleryActivate
                        galleryTitle='Mirror Gallery'
                        data={galleryQuery.mirror}
                    >
                        View
                    </GalleryActivate>
                </ProjectItem>
                <ProjectItem
                    image={galleryQuery.benchPromo}
                    alt='Bench rendered image'
                    heading='Sunshine garden bench'
                    subheading='Olive ash'
                    headingColour='orange'
                >
                    <GalleryActivate
                        galleryTitle='Bench Gallery'
                        data={galleryQuery.bench}
                    >
                        View
                    </GalleryActivate>
                </ProjectItem>
            </div>
        </React.Fragment>
    );
}